// Credit: https://stackoverflow.com/a/14268260
const Utils = {

  isNumeric: function(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  },

  scrollToElement: function(elementToScrollTo) {
    if (!elementToScrollTo) {
      return;
    }

    const y = (elementToScrollTo.getBoundingClientRect().top + (window.pageYOffset - 65));
    window.scrollTo({
      top: y,
    });
  },

  /**
   * Attaches our custom sort functionality to our table onload.
   * @param {*} parent
   */
  makeAllSortable: function (parent, exclude) {
    parent = parent || document.body;
    var t = parent.getElementsByTagName('table'), i = t.length;
    while (--i >= 0) {
      this.makeSortable(t[i], exclude);
    };
  },

  /**
   * Internal helper to make titles sortable in <thead>.
   * @param {*} table
   */
  makeSortable: function (table, exclude) {
    var th = table.tHead, i;
    th && (th = th.rows[0]) && (th = th.cells);
    if (th) i = th.length;
    else return; // if no `<thead>` then do nothing
    const self = this;
    while (--i >= 0) (function (i) {
      var dir = 1;

      exclude.forEach(function(classToExclude) {
        if(!th[i].classList.contains(classToExclude)) {
          th[i].addEventListener('click', function () {
            const elements = document.querySelectorAll('th[scope="col"]');
            if(!elements.length) {
              self.sortTable(table, i, (dir = 1 - dir));
            }
            else {
              elements.forEach(function (element) {
                if (element.classList.contains('active')) {
                  element.classList.remove('active');
                }
                if (element.classList.contains('desc')) {
                  element.classList.remove('desc');
                }
                if (element.classList.contains('asc')) {
                  element.classList.remove('asc');
                }

                // const numericColumns = [
                //   "Beds",
                //   "Baths",
                //   "Sleeps"
                // ];

                // if (!element.classList.contains('numeric') && numericColumns.includes(element.textContent.trim())) {
                //   element.classList.add('numeric');
                // }
              });

              const rotatingListItems = document.querySelectorAll('.dcv-rotating-item');
              rotatingListItems.forEach(function (element) {
                if (element.classList.contains('selected')) {
                  element.classList.remove('selected');
                }
              });
              this.classList.add('active');

              const direction = 1 - dir === 1 ? 'desc' : 'asc';
              this.classList.add(direction);

              // if(this.classList.contains('numeric')) {
              //   self.sortTableNumeric(table, i, (dir = 1 - dir));
              // }
              //else {
                self.sortTable(table, i, (dir = 1 - dir));
              //}

            }
          });
        }
      });
    }(i));
  },

  /**
    * Internal helper function to sort our view/table results by the selected
    * alphabetical sort.
    * @param {*} table
    * @param {*} col
    * @param {*} reverse
    */
  sortTable: function (table, col, reverse) {
    var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
      tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
      i;

    reverse = -((+reverse) || -1);
    const self = this;
    tr = tr.sort(function (a, b) { // sort rows
      const first = a.cells[col].textContent.trim();
      const second = b.cells[col].textContent.trim();
      // If numeric, sort as number
      if (self.isNumeric(first)) {
        return reverse * (parseFloat(first) - parseFloat(second));
      }
      return reverse * (first.localeCompare(second));
    });
    for (i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
  },

  /**
    * Internal helper function to sort our view/table results by the selected
    * numeric sort.
    * @param {*} table
    * @param {*} col
    * @param {*} reverse
    */
  sortTableNumeric: function (table, col, reverse) {
    var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
      tr = Array.prototype.slice.call(tb.rows, 0), // put rows into array
      i;

    tr = tr.sort(function (a, b) { // sort rows
      return parseFloat(a.cells[col].textContent.trim()) < parseFloat(b.cells[col].textContent.trim())
    });

    for (i = 0; i < tr.length; ++i) tb.appendChild(tr[i]); // append each row in order
  },

  /**
    * Internal helper function to sort our view/table results by the selected
    * alpha-numeric sort.
    * @param {*} table
    * @param {*} col
    * @param {*} reverse
    */
  sortTableByValue: function (table, col, boostedValue, rows) {
    var tb = table.tBodies[0], // use `<tbody>` to ignore `<thead>` and `<tfoot>` rows
      tr = Array.prototype.slice.call(rows, 1), // put rows into array
      i;

    const elements = document.querySelectorAll('th[scope="col"]');
    if(elements.length) {
      elements.forEach(function (element) {
        if (element.classList.contains('active')) {
          element.classList.remove('active');
        }
        if (element.classList.contains('desc')) {
          element.classList.remove('desc');
        }
        if (element.classList.contains('asc')) {
          element.classList.remove('asc');
        }
      });
    }

    var groupedTr = [];

    for (i = 0; i < tr.length; ++i) {
      var groupKey = tr[i].cells[col].textContent.trim();

      var newGroupItem = {
        gKey: groupKey,
        row: tr[i],
      }
      groupedTr.push(newGroupItem);
    }

    groupedTr.forEach(function(item, index) {
      if (item.gKey == boostedValue) {
        groupedTr.splice(index);
      }
    });

    groupedTr.forEach(function (item, index) {
      tb.appendChild(item.row);
    });
  },
};

export {
  Utils as default
}
