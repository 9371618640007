import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../lib/util';

// Import our own custom components.
import DCVRotatingListItem from '../dcv-rotating-list-nav-item/index.jsx';

const DCVRotatingListNavigation = () => {

  const classes = [
    "dcv-rotating-list-letters"
  ];

  return (
    <div className={classes}>
      <div className="alpha-numeric-list">
        <DCVRotatingListItem />
      </div>
    </div>
  );
};

export {
  DCVRotatingListNavigation as default
};
