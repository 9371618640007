import React, { useState, useEffect } from 'react';
import Utils from '../../lib/util';

const DCVRotatingListItem = () => {

  // Collect tables from the current document's context for processing.
  // @TODO: more intelligent way of doing this?
  const tables = document.body.getElementsByClassName('views-table cols-8');
  if(!tables.length) {
    return;
  }
  const tableObj = jQuery(tables[0]).get()[0];
  const originalRowOrder = [...tableObj.rows];

  // Neat way to grab the entire alphabet as an array in ES6.
  const alphabet = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];
  alphabet.push('0-9');
  const maxChar = Drupal.settings.dcv_rolling_list ? Drupal.settings.dcv_rolling_list.max_char : "0-9";
  const validChars = Drupal.settings.dcv_rolling_list ? Drupal.settings.dcv_rolling_list.valid_chars : alphabet;

  const filteredAlphabet = alphabet.filter(letter => {
    return letter.localeCompare(maxChar) <= 0
  });

  const defaultSort = {
    sort: filteredAlphabet[Math.floor(Math.random() * filteredAlphabet.length)],
  };

  const [defaultRowOrder, setDefaultRowOrder] = useState(originalRowOrder);
  const [currentSelectedSort, setCurrentSelectedSort] = useState(defaultSort);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const setSelected = (e) => {
    // Add new selected item.
    setCurrentSelectedSort({
      sort: e.target.textContent,
      element: e.target
    });
  };

  /**
   * When our row state is updated, we execute this code.
   */
  useEffect(() => {
    // Attach sorting to table headers.
    Utils.makeAllSortable(document.body, ['views-field-vrweb-first-letter']);
  }, [defaultRowOrder]);

  /**
   * When our component first mounts to the page, we need to sort the table by the
   * "boosted" alphabetical/numeric character.
   */
  useEffect(() => {
    // Reset our table rows to the default.
    if (!tables.length) {
      return;
    }

    const sortToSet = currentSelectedSort.sort;
    const tableHead = tableObj.tHead;

    if(!tableHead) {
      return;
    }

    const rows = defaultRowOrder;
    const cells = rows[0].cells;

    const boostedRows = document.getElementsByClassName("group-" + sortToSet);

    /**
     * Confirm we have rows we want to boost (i.e. a selected sort)
     * and that we have valid rows in our table.
     *
     * Additionally, confirm that at least the first row has valid row `cells` (values.)
     */
    if (!boostedRows.length || !rows.length || !cells.length) {
      return;
    }

    let cellColumns = cells.length ? cells.length : 0;

    if (cellColumns <= 0) {
      return;
    }

    // 8 cells, currently (0 - 7 index)
    while (--cellColumns >= 0) (function (cellColumns) {
      Utils.sortTableByValue(tableObj, cellColumns, currentSelectedSort.sort, defaultRowOrder);
    }(cellColumns));

    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    // Make soure our table(s) have length.
    if (!tables.length || isFirstRender) {
      return;
    }

    const sortToSet = currentSelectedSort.sort;

    // Make sure the selected group to scroll to exists.
    if (!document.querySelectorAll(".group-" + sortToSet).length) {
      return;
    }

    // We only care about the first item at the top of the group.
    const groupToScrollTo = document.querySelectorAll(".group-" + sortToSet)[0];

    Utils.scrollToElement(groupToScrollTo, groupToScrollTo.offsetHeight);
  }, [currentSelectedSort.sort]);

  const listItems = alphabet.map(item => {
    const selected = currentSelectedSort.sort === item ? " selected " : "";
    const enabled = validChars.includes(item.toUpperCase()) || item == "0-9" ? " enabled" : " disabled";

    return (
      <li className={`dcv-rotating-item` + selected + enabled} key={item}>
        <h3 onClick={(event) => setSelected(event)}>
          {item}
        </h3>
      </li>
    )
  });
  return (
    <ul className="dcv-rotating-list-items">
      {listItems}
    </ul>
  );
};

export {
  DCVRotatingListItem as default
};
